import {CategoryItem} from 'src/apps/search/types';
import {useMainStore} from 'stores/main';


export function useSearchTypeCategories() {
  const mainStore = useMainStore()

  const catVehicle: Array<CategoryItem> = [
    {
      label: 'Vom Händler',
      name: 'business',
      icon: 'fas fa-briefcase',
      filterData: {
        is_business: true
      }
    },
    {
      label: 'Autark',
      name: 'autark',
      icon: 'fas fa-tent',
      filterData: {vehicle__extras__slug: ['battery', 'solar', 'water_tank', 'toilet']}
    },
    {
      label: 'Winter',
      name: 'winterSport',
      icon: 'fas fa-mountain',
      filterData: {vehicle__extras__slug: ['heater', 'hot_water',]}
    },
    {
      label: 'Familie',
      name: 'family',
      icon: 'fas fa-people-group',
      filterData: {
        vehicle__seats_min: 4,
        vehicle__beds_min: 4,
      }
    },
    {
      label: '4x4',
      name: '4x4',
      icon: 'fas fa-truck-monster',
      filterData: {
        vehicle__all_wheel_drive: true,
        vehicle__car_type: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Low Budget',
      name: 'low_budget',
      icon: 'fas fa-percent',
      filterData: {
        price_max: 30_000,
      }
    },
    {
      label: 'Luxus',
      name: 'luxurious',
      icon: 'fas fa-dollar-sign',
      filterData: {
        price_min: 100_000,
      }
    },
    {
      label: 'Remote Work',
      name: 'remoteWork',
      icon: 'fas fa-briefcase',
      filterData: {
        vehicle__extras__slug: ['battery', 'inverter'],
      }
    },
    {
      label: 'Campervan',
      name: 'campervan',
      image: require('src/assets/camper_type_icons/icon_camper.svg'),
      filterData: {
        vehicle__car_type: 'campervan',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Tiny Camper',
      name: 'tiny_camper',
      image: require('src/assets/camper_type_icons/icon_tiny_camper.svg'),
      filterData: {
        vehicle__car_type: 'tiny_camper',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Alkoven',
      name: 'alkoven',
      image: require('src/assets/camper_type_icons/icon_camper.svg'),
      filterData: {
        vehicle__car_type: 'alkoven',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Integrierter',
      name: 'integrierter',
      image: require('src/assets/camper_type_icons/icon_wohnmobil_integriert.svg'),
      filterData: {
        vehicle__car_type: 'integrierter',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Teilintegrierter',
      name: 'teilintegrierter',
      image: require('src/assets/camper_type_icons/icon_wohnmobil_integriert.svg'),
      filterData: {
        vehicle__car_type: 'teilintegrierter',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Wohnwagen',
      name: 'wohnwagen',
      image: require('src/assets/camper_type_icons/icon_wohnwagen.svg'),
      filterData: {
        vehicle__car_type: 'wohnwagen',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Selbstausbauten',
      name: 'ownBuild',
      icon: 'fas fa-tools',
      filterData: {vehicle__build_type: 'own'},
    },
    {
      label: 'Oldtimer',
      name: 'oldtimer',
      image: require('src/assets/camper_type_icons/recreational-vehicle.png'),
      filterData: {
        vehicle__is_oldtimer: true,
        vehicle__car_type: undefined,
        vehicle__all_wheel_drive: undefined
      }
    },
    {
      label: 'Liner',
      name: 'liner',
      image: require('src/assets/camper_type_icons/recreational-vehicle.png'),
      filterData: {
        vehicle__car_type: 'liner',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Andere',
      name: 'other',
      image: require('src/assets/camper_type_icons/recreational-vehicle.png'),
      filterData: {
        vehicle__car_type: 'other',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    // {label: 'Wassersport', name: 'waterSport', icon: 'fas fa-water'},
    // {label: 'Festival', name: 'festival', icon: 'fas fa-guitar'},
    // {label: 'Barrierefrei', name: 'wheelchair', icon: 'fas fa-wheelchair'},
    // {label: 'Elektrisch', name: 'electric', icon: 'fa-solid fa-plug'},
    // {label: 'Öko', name: 'eco', icon: 'fas fa-leaf'},

  ]

  // const catMarket: Array<CategoryItem> = [
  //   {label: 'Gesuche', name: 'requests', filterData: {item_type: 'request'}},
  //   {label: 'Verschenken', name: 'give_away', filterData: {offer_type: 'give_away'}},
  // ]

  const categories = {
    vehicle: catVehicle,
    dealer: () => {
      const services = mainStore.businessServicesAvailable.map(item => ({
        label: item.name,
        name: item.name.toLowerCase().replace(' ', '_'),  // replace spaces with underscores for the name
        filterData: {services: [item.id]}
      }));

      return [
        ...services
      ]
    },
    // market: catMarket,
    market: undefined,
  }

  function getSearchTypeCategories(name: string) {
    if (typeof categories[name] === 'function') {
      return categories[name]()
    }
    return categories[name]
  }

  function getSearchTypeCategory(name: string, catName: string) {
    const cats = getSearchTypeCategories(name)
    if (!cats) {
      console.debug('No categories found for search type', name)
      return {}
    }
    try {
      return cats.find(cat => cat.name === catName) ?? {}
    } catch (err) {
      console.error('Error getting category', cats, err)
      return {}
    }
  }

  return {
    getSearchTypeCategories,
    getSearchTypeCategory,
  }
}

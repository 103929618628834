import {api} from 'boot/axios';

export default {
  fetchBusinessList: function () {
    return api.get('business/accounts/')
  },

  fetchBusiness: function (slug) {
    return api.get(`business/accounts/${slug}/`)
  },

  fetchBusinessProfileVisits: function (slug, days: number | undefined = undefined) {
    return api.get(`business/accounts/${slug}/profile-visits/`, {params: {days}})
  },

  putBusiness: function (slug, data) {
    return api.put(`business/accounts/${slug}/`, data)
  },

  patchBusiness: function (slug, data) {
    return api.patch(`business/accounts/${slug}/`, data)
  },

  fetchBusinessOptions: function (slug) {
    return api.options(`business/accounts/${slug}/`)
  },

  fetchBusinessPublicList: function (params: object | undefined = undefined, cancelToken: any = undefined) {
    return api.get('business/public/', {params, cancelToken})
  },

  fetchBusinessPublicDetails: function (slug) {
    return api.get(`business/public/${slug}/`)
  },

  fetchBusinessPublicAdvertList: function (slug, params: undefined | object = undefined) {
    return api.get(`business/public/${slug}/adverts/`, {params})
  },

  getAvailableFilter: function (businessSlug) {
    return api.get(`business/public/${businessSlug}/adverts/available-filter/`)
  },

  fetchBusinessAdverts: function (slug, params: undefined | object = undefined) {
    return api.get(`business/accounts/${slug}/adverts/`, {params})
  },

  fetchBusinessItems: function (slug, params: undefined | object = undefined) {
    return api.get(`business/accounts/${slug}/items/`, {params})
  },

  fetchBusinessServices: function () {
    return api.get('business/services/')
  },

  fetchBusinessServicesAvailable: function () {
    return api.get('business/services/available/')
  },

  fetchLocation: function (slug, locationId) {
    return api.get(`business/accounts/${slug}/locations/${locationId}/`)
  },

  fetchLocations: function (slug) {
    return api.get(`business/accounts/${slug}/locations/`)
  },

  createLocation: function (slug, data) {
    return api.post(`business/accounts/${slug}/locations/`, data)
  },

  updateLocation: function (slug, locationId, data) {
    return api.put(`business/accounts/${slug}/locations/${locationId}/`, data)
  },

  deleteLocation: function (slug, locationId) {
    return api.delete(`business/accounts/${slug}/locations/${locationId}/`)
  },

  optionsLocation: function (slug) {
    return api.options(`business/accounts/${slug}/locations/`)
  },

  createBusinessInvitation: function (businessSlug, email) {
    return api.post(`business/accounts/${businessSlug}/invitations/`, {email})
  },

  fetchBusinessInvitations: function (businessSlug) {
    return api.get(`business/accounts/${businessSlug}/invitations/`)
  },

  deleteBusinessInvitation: function (businessSlug, id) {
    return api.delete(`business/accounts/${businessSlug}/invitations/${id}/`)
  },

  verifyBusinessInvitation: function (token) {
    return api.get(`business/business-account-verification/${token}/`)
  },

  deleteBusinessRelation: function (businessSlug, userUuid) {
    return api.delete(`business/accounts/${businessSlug}/relations/${userUuid}/`)
  },

  fetchBusinessRegisterFormOptions: function () {
    return api.options('business/register/')
  },

  fetchSignupFormOptions: function () {
    return api.options('auth/signup/')
  },

  createBusiness: function (data) {
    return api.post('business/register/', data)
  },

  validateBusinessData(data) {
    return api.post('business/register/', data, {
      headers: {
        'Only-Validate': 'true'
      }
    })
  },

  fetchBusinessAutocomplete: function (query: string) {
    return api.get('search/business/suggest/', {params: {autocomplete_suggest__completion: query}})
  },

  toggleSubscriptionBusiness(businessSlug: string) {
    return api.post(`business/public/${businessSlug}/toggle-subscription/`)
  },
}

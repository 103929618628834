export default [
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/page/:slug/',
        component: () => import('pages/business/BusinessPublicPage.vue'),
        name: 'business-profile',
        meta: {hasBackBtn: true,}
      },
      {
        path: '',
        component: () => import('pages/business/BusinessAccountPage.vue'),
        meta: {noFooter: true, loginRequired: true},
        children: [
          {
            path: '/auth/business/:slug?/',
            component: () => import('pages/business/businessAccountPage/BADashboardPage.vue'),
            name: 'business-account',
          },
          {
            path: '/auth/business/:slug/adverts/',
            component: () => import('pages/business/businessAccountPage/BAAdvertsPage.vue'),
            name: 'business-account-adverts',
          },
          {
            path: '/auth/business/:slug/items/',
            component: () => import('pages/business/businessAccountPage/BAItemsPage.vue'),
            name: 'business-account-items',
          },
          {
            path: '/auth/business/:slug/edit/',
            component: () => import('pages/business/businessAccountPage/BAEditPage.vue'),
            name: 'business-account-edit',
          },
          {
            path: '/auth/business/:slug/team/',
            component: () => import('pages/business/businessAccountPage/BATeamPage.vue'),
            name: 'business-account-team',
          },
          {
            path: '/auth/business/:slug/widgets/',
            component: () => import('pages/business/businessAccountPage/BAWidgetsPage.vue'),
            name: 'business-account-widgets',
          },
          {
            path: '/auth/business/:slug/subscription/',
            component: () => import('pages/business/businessAccountPage/BASubscription.vue'),
            name: 'business-account-subscription',
          },
          {
            path: '/auth/business/:slug/widgets/:widgetUuid/',
            component: () => import('pages/business/businessAccountPage/widgets/AdvertListWidgetConfig.vue'),
            name: 'business-account-widgets-detail',
          },
          {
            path: '/auth/business/:slug/settings/',
            component: () => import('pages/business/businessAccountPage/BASettingsPage.vue'),
            name: 'business-account-settings',
          },
          {
            path: '/auth/business/:slug/notifications/',
            component: () => import('pages/business/businessAccountPage/BANotificationsPage.vue'),
            name: 'business-account-notifications',
          },
        ]
      },
    ]
  },
  {
    path: '',
    component: () => import('layouts/NoFooterOnMobileLayout.vue'),
    children: [
      {
        path: '/business/invitation/:token/',
        component: () => import('pages/business/BusinessAccountUserVerification.vue'),
        name: 'business-account-user-verification',
        meta: {loginRequired: true},
      }
    ]
  },
  {
    path: '',
    component: () => import('layouts/BackBtnNarrowLayout.vue'),
    children: [
      {
        path: '/business/register',
        component: () => import('src/apps/business/pages/BusinessRegisterPage.vue'),
        name: 'business-register',
        meta: {loginRequired: true}
      }
    ]
  }
]
